import { FC } from 'react';

import {
  useGetCurrentTariffQuery,
  useGetTariffsQuery,
} from '../../api/tariffs';
import {
  FinanceHeader,
  FinancePlan,
  FinancePlansGrid,
} from '../../components/ui/finance';
import { useAppSelector } from '../../state';
import { selectAuth } from '../../state/features/user/auth.slice';

export const FinancePage: FC = () => {
  const { data } = useGetTariffsQuery();

  const { userData } = useAppSelector(selectAuth);
  const { data: currentTariff } = useGetCurrentTariffQuery(
    userData?.id as string,
    {
      skip: !userData?.id,
    }
  );

  return (
    <div>
      <FinanceHeader currentName={currentTariff?.user?.tariff?.name} />
      <FinancePlansGrid>
        {[...(data ?? [])]
          .sort((a, b) => a.viewOrder - b.viewOrder)
          .map((tariff) => (
            <FinancePlan
              key={tariff.id}
              planName={tariff.name}
              color={tariff.viewColor}
              isCurrent={tariff.id === currentTariff?.user?.tariff?.id || false}
              features={{
                skuLimit: tariff.settings.renderCountLimit,
                price: tariff.monthlyPrice,
                connectionPrice: tariff.connectPrice,
              }}
            />
          ))}
      </FinancePlansGrid>
    </div>
  );
};

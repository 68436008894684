import { FC } from 'react';

import styled from '@emotion/styled';
import { Button, Space, Typography } from 'antd';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Typography.Title)`
  margin-top: 0;
  margin-bottom: 0 !important; // AntDesign не позволяет обойтись без !important
`;

const PrimaryText = styled(Typography.Text)`
  color: ${({ theme }) => theme.MAIN.ACCENT.C200};
`;

const ExpireText = styled(Typography.Text)`
  display: inline-block;
  max-width: 170px;
  text-align: right;
`;

type Props = {
  currentName?: string;
};

export const FinanceHeader: FC<Props> = ({ currentName }) => (
  <Header>
    <Space align="center" size={20}>
      <Title level={2}>Финансы</Title>
      <Space direction="vertical" size={0}>
        <Typography.Text>Ваш тариф</Typography.Text>
        <PrimaryText>{currentName ?? 'Тариф не найден'}</PrimaryText>
      </Space>
    </Space>
    {currentName && (
      <Space>
        <ExpireText>До окончания подписки осталось 7 дней</ExpireText>
        <Button type="primary">Продлить</Button>
      </Space>
    )}
  </Header>
);

import { baseEmptyApi } from '../api';
import { TCurrentTariffResponse, TTariffsResponse } from './types';

export const tariffsApi = baseEmptyApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getTariffs: builder.query<TTariffsResponse, void>({
      query: () => ({
        url: `api/tariffs`,
        method: 'GET'
      })
    }),
    getCurrentTariff: builder.query<TCurrentTariffResponse, string>({
      query: (userId) => ({
        url: `api/users/${userId}/tariff`,
        method: 'GET'
      })
    })
  })
})

export const {
  useGetTariffsQuery,
  useGetCurrentTariffQuery,
} = tariffsApi